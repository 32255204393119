import {
    ApiResponseBaseModel,
    ApiResponseModel,
    IPagedCollection,
    IRequestOptions,
    ProductStatusesFilter,
    RunnerItemsFilter,
} from "@dnr/data/models"
import { ServiceBase } from "@dnr/data/services"

import {
    ReceivingTransactionItemsQuery,
    ReceivingTransactionItemModel,
    CreateReceivingTransactionItemModel,
    UpdateReceivingTransactionItem,
    ReceivingTransactionItemStatus,
    IProductStatuses,
    ReceivingTransactionItemImage,
    UpdateWarehouseItemDisbursementOrder,
    IRunnerWarehouseItem,
} from "../models"
import { InventoryTransferItem, InventoryTransferRequest } from "../models/InventoryTransferItem"

class WarehouseItemsService extends ServiceBase {
    getLoggerName(): string {
        return "WarehouseItemsService"
    }

    async find(
        query?: ReceivingTransactionItemsQuery,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<ReceivingTransactionItemModel>>> {
        const response = await this.http.instance.get<
            ApiResponseModel<IPagedCollection<ReceivingTransactionItemModel>>
        >("/warehouse-items", {
            params: query,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async get(
        receivingTransactionItemId: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<ReceivingTransactionItemModel>> {
        const response = await this.http.instance.get<ApiResponseModel<ReceivingTransactionItemModel>>(
            `/warehouse-items/${receivingTransactionItemId}`,
            {
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async getInventoryTransferList(
        productIds: string[],
        dealerId?: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<InventoryTransferItem[]>> {
        const response = await this.http.instance.get<ApiResponseModel<InventoryTransferItem[]>>(
            "/warehouse-items/inventory-transfer-list",
            {
                params: {
                    productIds,
                    dealerIds: dealerId,
                },
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async transferInventory(data: InventoryTransferRequest): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.post<ApiResponseBaseModel>(
            "/warehouse-items/inventory-transfer",
            data
        )
        return response.data
    }

    async create(
        data: CreateReceivingTransactionItemModel[],
        senderUserId?: string
    ): Promise<ApiResponseModel<ReceivingTransactionItemModel[]>> {
        const response = await this.http.instance.post<ApiResponseModel<ReceivingTransactionItemModel[]>>(
            "/warehouse-items",
            {
                items: data,
                senderUserId: senderUserId,
            }
        )
        return response.data
    }

    async update(data: UpdateReceivingTransactionItem[]): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.put<ApiResponseBaseModel>("/warehouse-items", data)
        return response.data
    }

    async delete(itemId: string): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.delete<ApiResponseBaseModel>(`/warehouse-items/${itemId}`)
        return response.data
    }

    async getStatusesForCommitment(
        commitmentId: string,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<ReceivingTransactionItemStatus[]>> {
        const response = await this.http.instance.get<ApiResponseModel<ReceivingTransactionItemStatus[]>>(
            `/warehouse-items/${commitmentId}/warehouse-items-statuses`,
            {
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async updateImage(
        receivingTransactionItemId: string,
        data: FormData
    ): Promise<ApiResponseModel<ReceivingTransactionItemModel>> {
        const response = await this.http.instance.put<ApiResponseModel<ReceivingTransactionItemModel>>(
            `/warehouse-items/${receivingTransactionItemId}/images`,
            data
        )
        return response.data
    }

    async uploadImage(data: FormData): Promise<ApiResponseModel<ReceivingTransactionItemImage>> {
        const response = await this.http.instance.put<ApiResponseModel<ReceivingTransactionItemImage>>(
            "/warehouse-items/images",
            data
        )
        return response.data
    }

    async getDealerProductStatus(
        query?: ProductStatusesFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<IProductStatuses>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<IProductStatuses>>>(
            "/warehouse-items/dealer-product-status",
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async getRunnerProductStatus(
        query?: ProductStatusesFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<IProductStatuses>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<IProductStatuses>>>(
            "/warehouse-items/runner-product-status",
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async getRunnerItems(
        query?: RunnerItemsFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<IRunnerWarehouseItem>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<IRunnerWarehouseItem>>>(
            "/warehouse-items/runners",
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async updateWarehouseItemDisbursementOrder(
        warehouseItemId: string,
        data: UpdateWarehouseItemDisbursementOrder
    ): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>(
            `/warehouse-items/${warehouseItemId}/disbursement-order-id`,
            data
        )
        return response.data
    }

    async patchWarehouseItem(
        warehouseItemId: string
    ): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>(
            `/warehouse-items/${warehouseItemId}`
        )
        return response.data
    }
}

export default new WarehouseItemsService()
