import { action, makeObservable, observable } from "mobx"

import { IAuthenticatedUser } from "@dnr/data/models"

import { MainMenuItemModel } from "../models/MainMenuItemModel"

export class NavigationStore {
    user?: IAuthenticatedUser

    backofficeMenuElements: MainMenuItemModel[]
    portalMenuElements: MainMenuItemModel[]

    dealerMenuElements: MainMenuItemModel[]
    runnerMenuElements: MainMenuItemModel[]
    isCollapsed: boolean

    isMenuActive = false

    constructor(user?: IAuthenticatedUser) {
        this.user = user
        this.isCollapsed = false

        this.backofficeMenuElements = [
            {
                icon: "home",
                label: "MENU_ELEMENTS.HOME",
                path: "/app/dashboard",
                page: "dashboard",
                split: false,
            },
            {
                icon: "catalog",
                label: "MENU_ELEMENTS.CATALOG",
                path: "/app/products/list",
                page: "catalog",
                split: false,
            },
            {
                icon: "deals",
                label: "MENU_ELEMENTS.DEALS",
                path: "/app/deals/list",
                page: "deals",
                split: false,
            },
            {
                icon: "commitments",
                label: "MENU_ELEMENTS.COMMITMENTS",
                path: "/app/commitments/list",
                page: "commitments",
                split: false,
            },
            {
                icon: "trackings",
                label: "MENU_ELEMENTS.TRACKINGS",
                path: "/app/trackings",
                page: "trackings",
                split: false,
            },
            {
                icon: "user",
                label: "BACKOFFICE.MENU_ELEMENTS.USER_MANAGEMENT",
                split: false,
                page: "user-management",
                expanded: false,
                isActive: false,
                isOpened: false,
                children: [
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.USERS",
                        path: "/app/user-management/list/all",
                        childpage: "user-management",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.USER_REQUESTS",
                        path: "/app/user-management/user-requests",
                        childpage: "user-management",
                        split: false,
                    },
                ],
            },
            {
                icon: "warehouse",
                label: "MENU_ELEMENTS.WAREHOUSE",
                split: false,
                expanded: false,
                page: "warehouse",
                isActive: false,
                isOpened: false,
                children: [
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.RECEIVING_TRANSACTIONS",
                        childpage: "warehouse",
                        path: "/app/warehouse/receiving/list",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.INVENTORY",
                        childpage: "warehouse",
                        path: "/app/warehouse/inventory",
                        split: false,
                    },
                    {
                        label: "SHIPPING_REQUESTS.SHIPPING_REQUESTS",
                        childpage: "warehouse",
                        path: "/app/warehouse/shipping-requests",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.SHIPPING_TRANSACTIONS",
                        childpage: "warehouse",
                        path: "/app/warehouse/shipping/list",
                        split: false,
                    },
                ],
            },
            {
                icon: "finances",
                label: "BACKOFFICE.MENU_ELEMENTS.FINANCES",
                split: true,
                expanded: false,
                page: "finances",
                isActive: false,
                isOpened: false,
                children: [
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.BALANCE",
                        path: "/app/finances/balance",
                        childpage: "finances",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.TRANSACTIONS",
                        path: "/app/finances/transactions",
                        childpage: "finances",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.DISBURSEMENTS",
                        path: "/app/finances/disbursements",
                        childpage: "finances",
                        split: false,
                    },
                ],
            },
            {
                icon: "tickets",
                label: "MENU_ELEMENTS.TICKETS",
                path: "/app/tickets",
                page: "tickets",
                split: false,
                isActive: false,
                isOpened: false,
            },
            {
                icon: "settings",
                label: "MENU_ELEMENTS.SETTINGS",
                split: false,
                page: "settings",
                isActive: false,
                isOpened: false,
                children: [
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.ACCOUNT",
                        path: "/app/settings",
                        childpage: "settings",
                        split: false,
                    },
                    {
                        label: "GENERAL.CATALOG_MANAGEMENT",
                        path: "/app/catalog",
                        childpage: "settings",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.MENU_ELEMENTS.LEVEL_MANAGEMENT",
                        path: "/app/level-management",
                        childpage: "settings",
                        split: false,
                    },
                    {
                        label: "BACKOFFICE.WAREHOUSE_MANAGEMENT.LAYOUT_TITLE",
                        path: "/app/warehouse-management",
                        childpage: "settings",
                        split: false,
                    },
                ],
            },
            // {
            //     icon: 'chat',
            //     label: 'MENU_ELEMENTS.CHAT',
            //     path: '/app/chat',
            //     page: 'chat',
            //     split: false,
            //     isActive: false,
            //     isOpened: false,
            // },
        ]
        this.dealerMenuElements = [
            {
                icon: "my-deals",
                label: "MENU_ELEMENTS.MY_DEALS",
                path: "/app/deals/my",
                page: "my-deals",
                split: false,
            },
            {
                icon: "catalog",
                label: "MENU_ELEMENTS.CATALOG",
                path: "/app/products",
                page: "catalog",
                split: false,
            },
            {
                icon: "warehouse",
                label: "MENU_ELEMENTS.WAREHOUSE",
                split: false,
                page: "warehouse",
                isActive: false,
                children: [
                    {
                        label: "Inventory",
                        childpage: "warehouse",
                        path: "/app/warehouse/inventory",
                        split: false,
                    },
                    {
                        label: "Shipping requests",
                        childpage: "warehouse",
                        path: "/app/warehouse/shipping-requests",
                        split: false,
                    },
                ],
            },
        ]
        this.runnerMenuElements = [
            {
                icon: "deals",
                label: "MENU_ELEMENTS.DEALS",
                path: "/app/deals/list",
                page: "deals",
                split: false,
            },
            {
                icon: "commitments",
                label: "MENU_ELEMENTS.COMMITMENTS",
                path: "/app/commitments",
                page: "commitments",
                split: false,
            },
            {
                icon: "trackings",
                label: "MENU_ELEMENTS.TRACKINGS",
                path: "/app/trackings",
                page: "trackings",
                split: false,
            },
        ]
        this.portalMenuElements = [
            {
                icon: "home",
                label: "MENU_ELEMENTS.HOME",
                path: "/app/dashboard",
                page: "dashboard",
                split: false,
            },
            ...(this.user?.roles.includes("Dealer") ? this.dealerMenuElements : []),
            ...(this.user?.roles.includes("Runner") ? this.runnerMenuElements : []),
            {
                icon: "balance",
                label: "MENU_ELEMENTS.BALANCE_OVERVIEW",
                path: "/app/balance",
                page: "balance",
                split: true,
            },
            // {
            //     icon: 'chat',
            //     label: 'MENU_ELEMENTS.CHAT',
            //     path: '/app/chat',
            //     page: 'chat',
            //     split: false,
            // },
            {
                icon: "tickets",
                label: "MENU_ELEMENTS.TICKETS",
                path: "/app/tickets",
                page: "tickets",
                split: false,
            },
            {
                icon: "settings",
                label: "MENU_ELEMENTS.SETTINGS",
                path: "/app/settings",
                page: "settings",
                split: false
            },
            // {
            //     icon: 'faq',
            //     label: 'MENU_ELEMENTS.FAQ',
            //     path: '/app/faq',
            //     page: 'faq',
            //     split: false,
            // },
        ]

        makeObservable(this, {
            backofficeMenuElements: observable,
            portalMenuElements: observable,
            dealerMenuElements: observable,
            runnerMenuElements: observable,
            isCollapsed: observable,
            isMenuActive: observable,

            setIsActive: action,
            setIsMenuActive: action,
            setUser: action,
            setIsOpened: action,
            handleCollapse: action,
        })
    }

    setUser(value: IAuthenticatedUser) {
        this.user = value
    }

    setIsMenuActive() {
        this.isMenuActive = !this.isMenuActive
    }

    handleCollapse(isCollapsed: boolean) {
        this.isCollapsed = !isCollapsed
    }

    setIsOpened(env: string, element?: string, value?: boolean) {
        switch (env) {
            case "backoffice":
                for (const el of this.backofficeMenuElements) {
                    if (element === el.page) {
                        el.isOpened = value
                    } else {
                        el.isOpened = false
                    }
                }
                break
            case "portal":
                for (const el of this.portalMenuElements) {
                    if (element === el.page) {
                        el.isOpened = value
                    } else {
                        el.isOpened = false
                    }
                }
                break
            default:
                break
        }
    }

    setIsActive(env: string, element?: string) {
        switch (env) {
            case "backoffice":
                for (const el of this.backofficeMenuElements) {
                    if (el.children) {
                        for (const childElement of el.children) {
                            if (childElement.childpage === element) {
                                el.isActive = true
                            } else {
                                el.isActive = false
                            }
                        }
                    }
                }
                break
            case "portal":
                for (const el of this.portalMenuElements) {
                    if (el.children) {
                        for (const childElement of el.children) {
                            if (childElement.childpage === element) {
                                el.isActive = true
                            } else {
                                el.isActive = false
                            }
                        }
                    }
                }
                break
            default:
                break
        }
    }
}
