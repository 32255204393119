import { forwardRef, useEffect, useMemo, useState } from "react"

import { useOutsideClick, useTrigger } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import clsx from "clsx"
import { OutlineButton } from "@dnr/ui/buttons"

export interface DropdownOption<TData = void> {
    key: string
    value: string
    displayText: string
    data?: TData
    icon?: string
}

export type DropdownProps = {
    id: string
    label?: string
    options?: DropdownOption[]
    selectedValue?: string
    placeholder: any
    type?: string
    direction?: string
    size?: string
    width?: string
    color?: string
    onChange?: (value: DropdownOption) => void
    iserror?: boolean
    required?: boolean
    fullWidth?: boolean
    resetStates?: number | string
    env?: "portal" | "backoffice"
    disabled?: boolean
    disabledMessage?: string
    isFilter?: boolean
    resetFilter?: () => void
}

export const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>((props, ref) => {
    const { t } = useTranslate()
    const [isOpen, setIsOpen] = useState(false)
    const { trigger, forceTrigger } = useTrigger()

    const [selectedKey, setIsSelected] = useState(props.selectedValue)

    const displayText = useMemo(() => {
        if (selectedKey && props.options) {
            const match = props.options.filter((f) => f.key === selectedKey)
            if (match && match.length > 0) {
                return match[0].displayText
            }
        }

        return props.placeholder
    }, [props.placeholder, selectedKey, props.options])

    const [displayIcon, setDisplayIcon] = useState(
        props.options?.find((item) => item.key === props.selectedValue)?.icon
    )
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false)

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const handleChange = (element: DropdownOption) => {
        if (props.onChange) props.onChange(element)
        setIsOptionSelected(true)
        setIsSelected(element.key)
        setDisplayIcon(element.icon ? element.icon : "")
        handleIsOpen()
    }

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        setIsSelected(props.selectedValue || props.placeholder)
        setIsOptionSelected(false)
        setDisplayIcon(props.options?.find((item) => item.key === props.selectedValue)?.icon)
    }, [props.resetStates, trigger])

    const handleReset = () => {
        if (props.resetFilter) props.resetFilter()
        forceTrigger()
        setIsOptionSelected(false)
    }

    return (
        <div
            className={clsx(
                "c-input--select__wrapper",
                props.width === "full" ? "u-display--flex-col-fill" : "",
                props.fullWidth ? "fullWidth" : ""
            )}
        >
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label" htmlFor={props.id}>
                        {props.label} {props.required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                </div>
            ) : null}

            {props.disabled ? (
                <div className="c-error--warning c-error--xsml u-mb--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__warning__message">{props.disabledMessage}</span>
                </div>
            ) : null}

            <div
                className={clsx(
                    "c-input c-input--select",
                    props.size ? `c-input--${props.size}` : "c-input--base",
                    props.width ? `c-input--${props.width}` : "",
                    props.color === "light" ? "c-input--select--light" : "",
                    props.iserror ? "c-input--error" : "",
                    props.disabled ? "disabled" : "",
                    isOpen ? "isOpen" : ""
                )}
                // @ts-ignore: allowed
                ref={innerRef}
                onClick={() => {
                    if (!props.disabled) {
                        handleIsOpen()
                    }
                }}
            >
                <div
                    className={clsx("c-input--select__text", isOptionSelected ? "c-input--select__text--selected" : "")}
                >
                    {displayIcon ? <i className={`u-icon--stock-status u-icon u-icon--${displayIcon}`}></i> : null}{" "}
                    {displayText}
                </div>
                <div
                    className={clsx(
                        "c-input--select__content",
                        props.direction === "up" ? "c-input--select__list--top" : "c-input--select__list--bottom",
                        isOpen ? "isOpen" : ""
                    )}
                >
                    <div className="c-input--select__list">
                        {props.options != null && props.options.length > 0
                            ? props.options.map((item) => {
                                  return (
                                      <div
                                          className={clsx(
                                              "c-input--select__option",
                                              (selectedKey || props.selectedValue) === item.key
                                                  ? "c-input--select__option--active"
                                                  : ""
                                          )}
                                          key={item.key}
                                          onClick={() => handleChange(item)}
                                      >
                                          <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml">
                                              {item.icon ? (
                                                  <i className={`u-icon--stock-status u-icon u-icon--${item.icon}`}></i>
                                              ) : null}
                                              <span>{item.displayText}</span>
                                          </div>
                                      </div>
                                  )
                              })
                            : t.common("GENERAL.NO_DATA_TO_DISPLAY")}
                    </div>
                    {/*  */}
                    {props.isFilter ? (
                        <>
                            {" "}
                            <hr className="c-separator--color-light u-mt--sml--2 u-mb--sml--2" />
                            <OutlineButton size="xsml" haslabel width="full" onClick={handleReset}>
                                {t.common("GENERAL.RESET_FILTER")}
                            </OutlineButton>
                        </>
                    ) : null}{" "}
                </div>
            </div>
        </div>
    )
})
