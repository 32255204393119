import { action, computed, makeObservable, observable } from "mobx"

import { localStorageProvider } from "@dnr/util/storage"

import { tokenFlowService } from "@dnr/data/services"

import { IAuthenticatedUser, IToken } from "@dnr/data/models"

const tokenUpdatedEvent = new Event("tokenUpdated")

export class AuthStore {
    token?: IToken
    user?: IAuthenticatedUser
    lastVisitedPage?: string

    constructor() {
        makeObservable(this, {
            token: observable,
            user: observable,
            lastVisitedPage: observable,

            setUser: action,
            removeUser: action,
            initialize: action,
            setToken: action,
            syncToken: action,
            setLastVisitedPage: action,

            isAuthenticated: computed,
            companyName: computed,
            userLevel: computed,
            isDepositRequired: computed,
        })

        this.initialize()
    }

    get isDepositRequired() {
        let isRequired = null
        for (const acc in this.user?.accounts) {
            if (this.user?.accounts[Number(acc)].depositRequired) {
                isRequired = this.user?.accounts[Number(acc)].depositRequired
            }
        }
        return isRequired
    }

    get isAuthenticated() {
        return this.user != null && this.token !== null
    }

    get userLevel() {
        let level = 0
        for (const acc in this.user?.accounts) {
            if (this.user?.accounts[Number(acc)].levelId) {
                level = this.user?.accounts[Number(acc)].levelId
            }
        }
        return level
    }

    get companyName() {
        let companyName
        for (const acc in this.user?.accounts) {
            if (this.user?.accounts[Number(acc)].companyName) {
                companyName = this.user?.accounts[Number(acc)].companyName
            }
        }
        return companyName
    }

    initialize() {
        const user = localStorageProvider.get<IAuthenticatedUser>("user")
        if (user) {
            this.user = user.value
        }

        const token = localStorageProvider.get<IToken>("token")
        if (token) {
            this.token = token.value
        }

        window.addEventListener("invalidToken", () => this.syncToken())
        window.addEventListener("tokenExpired", () => this.syncToken())
    }

    async syncToken() {
        try {
            if (this.token?.token && this.token.refreshToken) {
                const response = await tokenFlowService.refreshToken({
                    accessToken: this.token.token,
                    refreshToken: this.token.refreshToken,
                })

                this.setToken({
                    token: response.accessToken,
                    refreshToken: response.refreshToken,
                    expireTime: response.expireTime,
                    refreshTokenExpiryDate: response.refreshTokenExpiryDate,
                })

                window.dispatchEvent(tokenUpdatedEvent)
            } else {
                this.removeUser()
            }
        } catch (err) {
            this.removeUser()
        }
    }

    setLastVisitedPage(pageUrl: string) {
        this.lastVisitedPage = pageUrl
    }

    setToken(token: IToken) {
        this.token = token

        localStorageProvider.set<IToken>("token", token)
    }

    setUser(user: IAuthenticatedUser) {
        this.user = user

        localStorageProvider.set("user", user)
    }

    removeUser() {
        this.user = undefined
        this.token = undefined

        localStorageProvider.remove("user")
        localStorageProvider.remove("token")
        localStorageProvider.remove("banner1-dashboard")
        localStorageProvider.remove("banner2-dashboard")
        localStorageProvider.remove("banner3-dashboard")
    }
}
